* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  background-color: #ededed;
  height: 100vh;
  display: flex;
}
