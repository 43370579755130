.sidebar {
  flex: 0.236;
  flex-shrink: 0;
  min-width: 340px;
  display: flex;
  flex-direction: column;
}

.sidebar {
  flex: 0.236;
  flex-shrink: 0;
  min-width: 340px;
  display: flex;
  flex-direction: column;
}

.sidebar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-right: 1px solid lightgray;
  background-color: #4f3951;
  height: 80px;
  padding-right: 10px;
}
.sidebar__headerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar__header > svg {
  height: 45px;
  width: 45px;
}
.sidebar__headerRight > button {
  outline: none;
  margin-left: 10px;
  padding: 0;
}

.sidebar__headerRight button .MuiSvgIcon-root {
  color: #e0e0e0;
}

.sidebar__headerRight button .MuiBadge-root .MuiSvgIcon-root {
  color: #ffffff;
}
.sidebar__headerAvatar {
  margin: 10px auto;
}
.sidebar__headerMenu {
  display: flex;
  justify-content: space-between;
  font-size: x-small;
}

.sidebar__headerMenu .MuiSvgIcon-root {
  margin-right: 5px;
}
.sidebar__headerLogout {
  margin-left: 15px;
  font-size: small;
}


.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 40px;
  border-bottom: 1px solid #ebebeb;
}

.sidebar__search > .MuiSvgIcon-root {
  color: gray;
  margin: 10px;
  margin-left: 40px;
  font-size: 18px;
}

.sidebar__search > input {
  border: none;
  outline: none;
  margin-left: 10px;
  flex: 1;
  font-size: small;
}

.sidebar__chat {
  background-color: #fff;
  flex: 1;
  overflow: scroll;
}

.sidebarChat {
  display: flex;
  padding: 10px;
  cursor: pointer;
  max-height: 60px;
  border-bottom: 1px solid #ebebeb;
}
.sidebarChat__select {
  background-color: #c0a5c5;
}

.sidebarChat:hover {
  background-color: #ebebeb;
}

.sidebarChat > .MuiAvatar-root {
  margin-left: 8px;
  height: 38px;
  width: 38px;
}

.sidebarChat__info > h2 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
}
.sidebarChat__info > p {
  font-size: x-small;
  color: #303030;
  margin-bottom: 2px;
}
.sidebarChat__info {
  flex: 1;
  margin-left: 15px;
}

.sidebarChat__infoRight {
  margin-right: 25px;
}
.sidebar__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
.sidebar__footer > .MuiButton-root {
  width: 80%;
  margin: 10px auto;
  outline: none;
  font-size: small;
}
