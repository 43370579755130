.message {
  flex: 0.764;
  display: flex;
  flex-direction: column;
}

.message__header {
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #4f3951;
  color: #ffffff;
  height: 80px;
  padding-left: 15px;
}

.message__headerInfo {
  flex: 1;
  padding-left: 15px;
  margin-top: 8px;
}

.message__headerInfo > h3 {
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 15px;
}

.message__headerInfo > p {
  color: #bdbdbd;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.message__headerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message__headerRight > .MuiButton-root {
  outline: none;
}
.message__body {
  flex: 1;
  padding: 30px;
  overflow: scroll;
}
.message__body > .MuiButtonBase-root {
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 100px;
  color: gray;
  margin-left: auto;
  outline: none;
  background-color: lightgray;
}
.message_bodyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: lightgray;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.message_bodyButton > p {
  margin-top: 12px;
  font-size: 14px;
}

.message_bodyButton > .MuiButton-root {
  width: 100%;
  height: 100%;
  outline: none;
}

.message_bodyButton > .MuiButton-root > .MuiButton-label {
  font-size: 12px;
}

.chat__message {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  padding: 12px;
  padding-right: 15px;
  max-width: 530px;
  width: fit-content;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 50px;
}
.chat__deleted {
  display: none;
}
.chat__message img {
  max-width: 450px;
  margin-bottom: 5px;
  width: fit-content;
}
.chat__message video {
  max-width: 450px;
  margin-bottom: 5px;
  width: fit-content;
}
.chat__message > p {
  word-wrap: break-word;
  margin-bottom: 0;
  max-width: 450px;
}
.chat__message > p > a {
  color: darkblue;
}
.chat__message iframe {
  max-width: 450px;
  margin-bottom: 5px;
  width: fit-content;
}

.chat__reciever {
  margin-left: auto;
  background-color: #cdc9ce;
}

.chat__timestamp {
  white-space: pre;
  margin-left: 10px;
  margin-right: 10px;
  font-size: xx-small;
  color: #58525a;
}

.chat__name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
  width: fit-content;
}

.chat__options > .MuiButtonBase-root {
  outline: none;
  position: absolute;
  top: -15px;
  right: -3px;
}
.chat__options > .MuiSvgIcon-root {
  color: gray;
  font-size: xx-small;
}

.replyMessage {
  margin: 5px 0;
  width: 100%;
  opacity: 1;
  border-radius: 4px;
  background-color: #eeeeee;
  border-left: 6px solid #4d3951;
  padding: 8px;
}

.replyMessage > .replyName {
  height: 16px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #4d3951;
  width: 100%;
}

.replyMessage > .replyText {
  color: #727070;
  font-size: 11px;
}

.message__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
  position: relative;
}
.message__footer > form {
  flex: 1;
  display: flex;
}
.message__footer > form > input {
  flex: 1;
  border-radius: 30px;
  font-size: 14px;
  padding: 10px;
  border: none;
  outline: none;
  z-index: 1;
}
.message__footer > form > button {
  outline: none;
}

.message__footer > .MuiButtonBase-root {
  outline: none;
}

.message__footer > .MuiSvgIcon-root {
  color: gray;
  margin: 10px;
}

.message__footer > .MuiButton-contained {
  margin-right: 15px;
  font-size: small;
}
aside.emoji-picker-react {
  position: absolute !important;
  left: 0 !important;
  bottom: 62px !important;
  width: 100% !important;
  height: 270px !important;
  overflow: scroll;
}

.message__reply {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 72px;
  margin: -11px 23px;
  width: 90%;
  height: auto;
  opacity: 1;
  border-radius: 4px;
  background-color: lightgray;
  border-left: 6px solid #4d3951;
  padding: 8px;
}

.message__reply > .message__replyText {
  font-size: 11px;
}

.FAQ_header {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 20px;
  font-weight: 700;
}
.FAQ_header > .MuiButtonBase-root {
  background-color: #c0a5c5;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.FAQ_header > .FAQ_headerRight {
  flex: 1;
  margin-left: 80px;
}

.FAQ_title {
  font-size: 13px;
}

.card__wrapper {
  width: 100%;
}
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.card button {
  margin-right: 5px;
  outline: none;
  max-width: 10%;
}
.card__content {
  margin-left: 5px;
  text-align: left;
  max-width: 85%;
  font-size: 13px;
}
.mediaupload {
  display: flex;
  height: 250px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mediaupload > input[type="file"] {
  margin: 10px 3px;
}

.mediaupload > input[type="text"] {
  padding: 10px;
  margin: 10px 3px;
  outline: none;
}

.mediaupload > div > p {
  margin-bottom: 10px;
  margin-left: 3px;
  font-size: small;
}

.userInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
  overflow-y: scroll;
  font-size: 13px;
}

.userInfo__row {
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

