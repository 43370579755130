* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.login {
  background-color: #4f3951;
  height: 100vh;
  display: flex;
}

.login__card {
  background-color: #ffffff;
  text-align: center;
  max-width: 480px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  height: 65vh;
  border: 1px solid #4f3951;
  border-radius: 8px;
}

.login__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2230;
  height: 80px;
  color: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.login__header > svg {
  height: 50px;
  width: 50px;
  margin-left: 15px;
}
.login__header > h2 {
  font-size: large;
  font-weight: 300;
  flex: 1;
  margin-bottom: -5px;
  margin-left: -50px;
}

.login__card > h2 {
  padding-bottom: 10px;
  padding-top: 30px;
  margin: auto;
  font-size: medium;
  font-weight: 300;
}

.login__card > p {
  font-size: x-small;
  color: #6b6969;
}

.form {
  display: flex;
}

.login__input {
  margin-top: 10px;
  font-size: small;
  padding-left: 10px;
  max-width: 100%;
  height: 35px;
  width: 321px;
  outline: none;
}

.login__error {
  margin-top: 5px;
  font-size: 12px;
  color: #ee7171;
}

.login__resend {
  height: 20px;
  width: 164px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 55px;
  position: absolute;
  right: -15px;
  color: #1976d2;
  font-size: small;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.login__resend.focus,
.login__resend:focus {
  outline: 0;
  box-shadow: none !important;
}
.login__button {
  border-radius: 5px;
  width: 65%;
  margin-top: 15px;
  outline: none;
  vertical-align: bottom;
  background: #4f3951;
  font-size: small;
  font-weight: 300;
  border: 2px solid #4f3951;
  height: 35px;
  color: #fff;
  padding: 0px 20px;
}
