.seed__user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  background: #ededed;
}
.seed__userInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 12px;
  color: #4f3951;
}
.seed__user > .MuiAvatar-root {
  width: 36px;
  height: 36px;
}
